import * as React from 'react';
import { RouteComponentProps, withRouter } from '../withRouter';

const scrollToTop = (smooth?: boolean): void => {
  try {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: smooth ? 'smooth' : 'auto',
    });
  } catch (e) {
    // fallback for IE
    window.scrollTo(0, 0);
  }
};

type Props = RouteComponentProps;

class ScrollToTop extends React.PureComponent<Props> {
  componentDidUpdate(prevProps: Readonly<Props>) {
    const { location } = this.props;
    if (location.pathname !== prevProps.location.pathname) {
      scrollToTop();
    }
  }

  render(): React.ReactNode {
    return <></>;
  }
}

export default withRouter(ScrollToTop);
