import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { destroyAuthentication } from "../../../utils/user";
import { Routes } from "../../../utils/routes";
import { UserRole } from "../../../utils/config";
import Icons from "./Icons";

function isConversations(path) {
  const reConversations = /\/conversations/g;
  return reConversations.test(path);
}

type Props = {
  pathname: string;
  user: any;
  userCompanies: any[];
  userConversations: any[];
  userNotifications: any[];
  userDisplayName?: string;
};

class ProtectedSidebarMobile extends React.PureComponent<Props> {

  handleLogout = (e) => {
    e.preventDefault();
    destroyAuthentication();
    window.location.href = Routes.SignIn;
  };

  getUnreadConversations = () => {
    const { user, userCompanies, userConversations, userNotifications } =
      this.props;

    let count = 0;
    userConversations.forEach((conversation) => {
      const lastMessage = conversation.messages[0];
      if (!lastMessage) {
        return;
      }

      if (lastMessage.from.userId === user.id) {
        return;
      }
      if (
        lastMessage.from.companyId &&
        userCompanies.some(
          (company) => company.id === lastMessage.from.companyId,
        )
      ) {
        return;
      }

      if (!lastMessage.readDatetime) {
        count += 1;
      }
    });

    const lastNotification = userNotifications.slice(-1).pop();
    if (lastNotification && !lastNotification.readDatetime) {
      count += 1;
    }

    return count;
  };

  render() {
    const { pathname, user } = this.props;
    const unreadConversations = this.getUnreadConversations();

    return (
      <>
        <div className="nav-item">
          <Link
            to="/oil-requests/create"
            className={`protected-link ${pathname === "/oil-requests/create" ? "protected-link-active" : ""}`}
          >
            {pathname === "/oil-requests/create" ?
              <div>{Icons.PlusYellowMobile}</div>
              :
              <div>{Icons.PlusMobile}</div>
            }
            Uus hange
          </Link>
        </div>
        <div className="nav-item">
          <Link
            to="/oil-requests"
            className={`protected-link ${pathname === "/oil-requests" ? "protected-link-active" : ""}`}
          >
            {pathname === "/oil-requests" ?
              <div>{Icons.CopyYellowMobile}</div>
              :
              <div>{Icons.CopyMobile}</div>
            }
            Hanked
          </Link>
        </div>
        <div className="nav-item">
          <Link
            to="/conversations"
            className={`protected-link ${isConversations(pathname) ? "protected-link-active" : ""}`}
          >
            <div>
              {isConversations(pathname) ?
                Icons.BellYellowMobile : (Icons.BellMobile)}
              {unreadConversations > 0 && (
                <span className="badge custom-badge"> {unreadConversations}</span>
              )}
            </div>
            <div>
              Sõnumid
            </div>
          </Link>
        </div>
        {user.role === "Admin" && (
          <div className="nav-item">
            <Link
              to="/admin"
              className={`protected-link ${pathname === "/admin" ? "protected-link-active" : ""}`}
            >
              {pathname === "/admin" ?
                <div>{Icons.SettingsYellowMobile}</div>
                :
                <div>{Icons.SettingsMobile}</div>
              }
              Admin
            </Link>
          </div>
        )}
        <div className="nav-item">
          <Link
            to="#"
            onClick={this.handleLogout}
            className="protected-link"
          >
            <div>{Icons.LogoutMobile}</div>
            Logi välja
          </Link>
        </div>
        <div className={`settings ${pathname === "/settings" ? "settings-active" : ""}`}>
          <Link
            to="/settings"
          >
            {pathname === "/settings" ?
              <div>{Icons.SettingsYellowMobile}</div>
              :
              <div>{Icons.SettingsMobile}</div>
            }
          </Link>
        </div>



      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    userConversations: state.userConversationsReducer.conversations,
    user: state.userReducer.user,
    userCompanies: state.userCompaniesReducer.companies,
    userNotifications: state.userNotificationsReducer.notifications
  };
}

export default connect(mapStateToProps)(ProtectedSidebarMobile);
